<template>
  <div id="wrapper" class="app w-100">
    <Nav />
    <div>
      <img
        class="background-image d-print-none"
        src="@/assets/img/plane_back.webp"
        alt=""
      />
      <div class="page container-fluid">
        <NuxtPage />
      </div>
    </div>
    <div v-if="runTimeConfig.public.isLoading">
      <Spinner />
    </div>

    <NuxtLoadingIndicator />
  </div>
</template>

<script setup>
let runTimeConfig = useRuntimeConfig();
</script>

<style scoped>
.page {
  color: black;
}
.background-image {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  object-fit: cover;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value to control the transparency of the overlay */
  z-index: -1;
}
</style>
